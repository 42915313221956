import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import type { InventoryResource, Snapshot } from '@repo/api-gw-sdk';
import { InventoryResourceResourceTypeEnum } from '@repo/api-gw-sdk';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { StyledPITPopperBottomBar } from '@/components/snapshots/StyledPITPopperBottomBar';
import { PointsInTimeCalendar } from '@/components/snapshots/pointsInTimeCalendar';
import VaultTag from '@/components/vaults/VaultTag';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';

import { BackingUpTooltip, PendingCircle, SnapshotRow } from './snapshotRow';

import { Panels } from '../../panels';

export const PointsInTimeTab = ({
  entity,
  resourceId,
}: {
  resourceId: string;
  entity: InventoryResource;
}) => {
  const dal = useDAL();
  const { isAuthorized } = useRoles();
  const [selected, setSelected] = useState<Date | undefined>();
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const { data: vaults } = useBackupVaults();

  const { body, isLoading } = dal.inventory.snapshots.list(
    resourceId,
    'eon',
    0,
    100
  );

  const restorePanel = {
    [InventoryResourceResourceTypeEnum.S3]: Panels.RestoreS3izard,
    [InventoryResourceResourceTypeEnum.Rds]: Panels.RestoreRDSWizard,
    [InventoryResourceResourceTypeEnum.AtlasCluster]:
      Panels.RestoreMongoAtlasWizard,
  }[entity.resourceType as unknown as string];

  useEffect(() => {
    if (body?.data) {
      setSnapshots(
        body.data.filter(
          (x) =>
            x.status === 'PENDING_DISABLED' ||
            (x.status === 'COMPLETED' && isDateValid(x.pointInTime))
        )
      );
    }
  }, [body]);

  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;

  const selectedDateSnapshots = selected
    ? (snapshots ?? [])
        .filter((e) => {
          const date = isDateValid(e.pointInTime)
            ? e.pointInTime
            : e.dateCreated;

          return date.toDateString() === selected?.toDateString();
        })
        .sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime())
    : [];

  return (
    <Box sx={{ margin: '24px auto 0' }}>
      <PointsInTimeCalendar
        isLoading={isLoading}
        onDateRangeChanged={() => {
          setSelected(undefined);
        }}
        events={(snapshots ?? []).map((x) => {
          const vault = vaults?.find((v) => v.id === x.vaultId);

          const date = isDateValid(x.pointInTime)
            ? x.pointInTime
            : x.dateCreated;

          const end = new Date(date);
          end.setMinutes(end.getMinutes() + 1);

          return {
            start: date,
            end,
            vaultName: vault?.name,
            indicatorColor: vault?.backgroundColor || null,
            status: x.status,
          };
        })}
        onDateSelected={(selected) => {
          setSelected(selected ? new Date(selected.toDateString()) : undefined);
        }}
      />
      <StyledPITPopperBottomBar>
        {selectedDateSnapshots.length > 0 &&
          selectedDateSnapshots.map((snap) => {
            const vault = vaults?.find((v) => v.id === snap.vaultId);

            if (restorePanel) {
              return (
                <SnapshotRow
                  key={snap.id}
                  selected={selected}
                  snap={snap}
                  vault={vault}
                  onRestore={() => {
                    setComponent({
                      panel: restorePanel,
                      props: {
                        resourceId: resourceId,
                        snapshotId: snap.id,
                        sourceRegion: vaults.find((x) => x.id === snap.vaultId)
                          ?.region,
                      },
                    });
                  }}
                />
              );
            }

            if (entity.resourceType === InventoryResourceResourceTypeEnum.Ec2) {
              if (!vault || !snap.properties?.volumeProperties?.length) {
                return null;
              }

              const date = isDateValid(snap.pointInTime)
                ? snap.pointInTime
                : snap.dateCreated;

              return (
                <Stack key={snap.id} className='w-full'>
                  {snap.properties.volumeProperties.map((vol) => (
                    <Stack
                      direction='row'
                      key={vol.volumeId}
                      className='mb-[8px] w-full'
                      alignItems='center'
                      justifyContent={'space-between'}
                    >
                      <Stack direction='row' alignItems='center' gap='16px'>
                        <VaultTag vault={vault} />
                        {snap.status === 'PENDING_DISABLED' && (
                          <PendingCircle
                            backgroundColor={vault.backgroundColor}
                          />
                        )}
                        <Typography variant='body1'>
                          {vol.volumeName}
                        </Typography>
                        <Typography variant='body1'>
                          {(snap.status === 'PENDING_DISABLED'
                            ? 'Started at '
                            : '') + dayjs(date).format('HH:mm:ss')}
                        </Typography>
                      </Stack>
                      <Box className='flex justify-end'>
                        {snap.status === 'PENDING_DISABLED' && (
                          <BackingUpTooltip />
                        )}
                        {snap.status === 'COMPLETED' && (
                          <Tooltip title='Convert to EBS Snapshot'>
                            <div>
                              <IconButton
                                disabled={
                                  !isAuthorized('create:restore_resource')
                                }
                                onClick={() => {
                                  setComponent({
                                    panel: Panels.RestoreVolumeWizard,
                                    props: {
                                      snapshotId: snap.id,
                                      instanceId: resourceId,
                                      volumeId: vol.volumeId,
                                      volumeRegion: vol.region,
                                      actionType: 'snapshot',
                                    },
                                  });
                                }}
                                className={'mr-[10px]'}
                              >
                                <i className='material-symbols-repeat-rounded' />
                              </IconButton>
                            </div>
                          </Tooltip>
                        )}
                        {snap.status === 'COMPLETED' && (
                          <Button
                            disabled={!isAuthorized('create:restore_resource')}
                            className='m-0'
                            variant='outlined'
                            size='small'
                            onClick={() =>
                              // Restore Volume
                              setComponent({
                                panel: Panels.RestoreVolumeWizard,
                                props: {
                                  instanceId: resourceId,
                                  volumeId: vol.volumeId,
                                  volumeRegion: vol.region,
                                  snapshotId: snap.id,
                                  actionType: 'volume',
                                },
                              })
                            }
                          >
                            Restore Volume
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              );
            }
          })}
      </StyledPITPopperBottomBar>
    </Box>
  );
};
