import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type { OptionMenuItemType } from '@/@core/components/option-menu/types';
import { Icon } from '@/components/shared/icon';
import { useRoles } from '@/contexts/useRoles';

export const RolePermissions = ({
  isProjectRole,
  isBuiltInRole,
  scopes,
  editMode,
  onChange,
  hideUnauthorizedPermissions,
  hidePermissionGroups,
}:
  | {
      isBuiltInRole: boolean;
      isProjectRole: boolean | undefined;
      scopes: string[];
      hideUnauthorizedPermissions?: boolean;
      hidePermissionGroups?: boolean;
      editMode?: false;
      onChange?: (permission: string, value: boolean) => void;
    }
  | {
      isBuiltInRole: boolean;
      isProjectRole: boolean | undefined;
      scopes: string[];
      editMode: true;
      hideUnauthorizedPermissions?: false;
      hidePermissionGroups?: false;
      onChange: (permission: string, value: boolean) => void;
    }) => {
  const { permissions, isAuthorized } = useRoles();

  const permissionsByCategory = Object.entries(permissions).reduce<
    Record<
      string,
      (Extract<(typeof permissions)[string], unknown> & {
        permission: string;
      })[]
    >
  >((agg, [permission, value]) => {
    agg[value.category] = agg[value.category] || [];
    agg[value.category].push({ permission, ...value });
    return agg;
  }, {});

  return (
    <Stack direction='row' className='w-full' justifyContent='space-evenly'>
      {Object.entries(permissionsByCategory)
        .filter(([category, permissions]) => {
          if (isProjectRole && category === 'Account') {
            return false;
          }

          if (!isBuiltInRole && !isProjectRole && category !== 'Account') {
            return false;
          }

          if (hideUnauthorizedPermissions) {
            return permissions.some((x) =>
              x.scopes.every((x) => isAuthorized(x, scopes))
            );
          }

          return true;
        })
        .map(([category, permissions], index, arr) => {
          const groupedPermissions = permissions.reduce<
            Record<string, typeof permissions>
          >((acc, perm) => {
            acc[perm.group || ''] = acc[perm.group || ''] || [];
            acc[perm.group || ''].push(perm);
            return acc;
          }, {});

          return (
            <React.Fragment key={category}>
              <Stack className='w-full'>
                <Typography
                  className='font-semibold cursor-default'
                  sx={{
                    margin: '12px 0 24px',
                  }}
                >
                  {category}
                </Typography>
                <Stack>
                  {Object.entries(groupedPermissions).map(
                    ([group, permissions]) => {
                      return (
                        <React.Fragment key={category + group}>
                          {group && !hidePermissionGroups && (
                            <Typography
                              className='mt-[24px] mb-[24px] cursor-default uppercase'
                              variant='body2'
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              {group}
                            </Typography>
                          )}
                          {permissions.map((permission) => {
                            const isPermissionAuthorized =
                              permission.scopes.every((x) =>
                                isAuthorized(x, scopes)
                              );

                            if (
                              hideUnauthorizedPermissions &&
                              !isPermissionAuthorized
                            ) {
                              return null;
                            }

                            const PermissionIcon = (
                              <Icon
                                iconClass={
                                  isPermissionAuthorized
                                    ? 'material-symbols-check'
                                    : 'material-symbols-close-rounded'
                                }
                                sx={{
                                  ...(editMode
                                    ? {
                                        cursor: 'pointer',
                                        backgroundColor: isPermissionAuthorized
                                          ? 'rgba(145,255,210,0.2)'
                                          : 'rgba(240,68,101,0.2)',
                                      }
                                    : {}),

                                  i: {
                                    color: isPermissionAuthorized
                                      ? 'var(--mui-palette-success-main)'
                                      : 'var(--mui-palette-error-main)',
                                  },
                                }}
                              />
                            );

                            return (
                              <Stack
                                data-testid='role-permission'
                                direction='row'
                                gap='12px'
                                justifyContent='space-between'
                                key={permission.permission}
                                className='pb-[8px]'
                                sx={{
                                  '&:hover i': {
                                    display: 'block !important',
                                  },
                                }}
                              >
                                <Stack
                                  direction='row'
                                  gap='12px'
                                  className='mt-[8px]'
                                >
                                  <Typography className='cursor-default'>
                                    {permission.permission}
                                  </Typography>
                                  {permission.description && (
                                    <Tooltip
                                      className='mt-[6px] hidden'
                                      title={permission.description}
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            padding: '16px',
                                          },
                                        },
                                      }}
                                    >
                                      <i className=' material-symbols-info-outline h-[20px] w-[20px] p-[2px]' />
                                    </Tooltip>
                                  )}
                                </Stack>
                                {editMode && (
                                  <PermissionOptionMenu
                                    isPermissionAuthorized={
                                      isPermissionAuthorized
                                    }
                                    onChange={(value) =>
                                      onChange(permission.permission, value)
                                    }
                                    icon={PermissionIcon}
                                  />
                                )}
                                {!editMode && PermissionIcon}
                              </Stack>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  )}
                </Stack>
              </Stack>
              {index < arr.length - 1 && (
                <Divider
                  orientation='vertical'
                  flexItem
                  className='mx-[40px]'
                />
              )}
            </React.Fragment>
          );
        })}
    </Stack>
  );
};

const optionsBuilder = (selectedIndex: number): OptionMenuItemType[] =>
  [
    { icon: 'material-symbols-close-rounded', text: 'Blocked' },
    { icon: 'material-symbols-check', text: 'Allowed' },
  ].map((x, index) => ({
    menuItemProps: {
      sx: (theme) => ({
        ...(selectedIndex === index
          ? {
              backgroundColor: 'var(--mui-palette-primary-main)',
              color: 'var(--mui-palette-background-default)',
            }
          : {}),

        '&:hover': {
          backgroundColor:
            selectedIndex === index
              ? 'var(--mui-palette-primary-main)'
              : theme.palette.mode === 'dark'
                ? '#1A2027'
                : '#E7F0F3',
        },
      }),
    },
    text: (
      <Stack alignItems='center' gap='12px' direction='row'>
        <Icon
          iconClass={x.icon}
          sx={{
            '& i': {
              color: 'currentColor',
            },
          }}
        />
        <Typography className='pr-[8px]' color='currentColor'>
          {x.text}
        </Typography>
      </Stack>
    ),
  }));

const PermissionOptionMenu = ({
  icon,
  onChange,
  isPermissionAuthorized,
}: {
  isPermissionAuthorized: boolean;
  icon: React.ReactNode;
  onChange: (isEnabled: boolean) => void;
}) => {
  const selectedIndex = isPermissionAuthorized ? 1 : 0;
  const options = optionsBuilder(selectedIndex);

  return (
    <OptionMenu
      iconButtonProps={{ className: 'p-0' }}
      icon={icon}
      options={options}
      onOptionSelected={(option) => {
        if (typeof option !== 'string') {
          onChange(options.indexOf(option) === 1);
        }
      }}
    />
  );
};
