import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

export const RDSSubnetGroupSelect = ({
  accountId,
  regionName,
  rdsSubnetGroupName,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  rdsSubnetGroupName?: string;
  onChange: (rdsSubnetGroupName: string, vpc: string) => void;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title='RDS subnet group' />;
  }

  return (
    <InnerRDSSubnetGroupSelect
      accountId={accountId}
      regionName={regionName}
      rdsSubnetGroupName={rdsSubnetGroupName}
      onChange={onChange}
    />
  );
};

const InnerRDSSubnetGroupSelect = ({
  accountId,
  regionName,
  rdsSubnetGroupName,
  onChange,
}: {
  accountId: string;
  regionName: string;
  rdsSubnetGroupName?: string;
  onChange: (rdsSubnetGroupName: string, vpc: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.rdsSubnetGroup.list(accountId, regionName);

  if (!body?.rdsSubnetGroups.length) {
    return <EmptySelect title='RDS subnet group' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>RDS subnet group</FormLabel>
      <Select
        size='small'
        value={rdsSubnetGroupName || ''}
        onChange={(event) =>
          onChange(
            event.target.value,
            body.rdsSubnetGroups.find((x) => x.name === event.target.value)
              ?.vpc || ''
          )
        }
      >
        {body.rdsSubnetGroups.map((value) => (
          <MenuItem key={value.name} value={value.name}>
            {`${value.name} (${value.vpc})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
