import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import type { FileSearchRecord, InventoryResource } from '@repo/api-gw-sdk';
import dayjs from 'dayjs';
import { Fragment } from 'react';

import { ActionsBar, ActionsToolBar } from '@/components/shared/actionsBar';
import {
  InventoryResourceActionsLevels,
  type Action,
} from '@/data/inventory/actions';
import { isDateValid } from '@/utils/dateTime';

interface InstanceActionsBarProps<
  T extends InventoryResource | FileSearchRecord,
> {
  actions: Partial<Record<InventoryResourceActionsLevels, Action<T>[]>>;
  entity: T;
}

export default function InstanceActionsBar<
  T extends InventoryResource | FileSearchRecord,
>(props: InstanceActionsBarProps<T>) {
  const { entity, actions } = props;

  let lastBackup: Date | undefined;
  if ('lastBackup' in entity) {
    // InventoryResource
    lastBackup = entity.lastBackup;
  } else if ('snapshots' in entity) {
    // FileSearchRecord
    lastBackup = entity.snapshots?.[0]?.generatedOn;
  }

  if (!isDateValid(lastBackup)) {
    lastBackup = undefined;
  }

  const primaryActions = actions[InventoryResourceActionsLevels.MainPrimary];
  const secondaryActions =
    actions[InventoryResourceActionsLevels.MainSecondary];

  return (
    <ActionsBar
      position='static'
      className='mb-0 px-[40px]'
      sx={{
        borderTop: '1px solid var(--border-color)',
      }}
    >
      <Container maxWidth='xl' disableGutters>
        <ActionsToolBar className='flex justify-between px-[0px]'>
          <Typography>
            {isDateValid(lastBackup)
              ? `Last backed up on ${dayjs(lastBackup).format('ll')}`
              : 'The last backup date is unknown'}
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {secondaryActions?.map((action, i) => (
              <Fragment key={action.title || action.tooltip}>
                <Tooltip title={action.tooltip}>
                  {/* adding this div so the tooltip will be shown also when the iconButton is disabled */}
                  <div className='my-auto align-middle'>
                    <IconButton
                      onClick={() => {
                        action.execute(props.entity);
                      }}
                      className='text-[18px] text-primary'
                      disabled={action.disabled?.()}
                    >
                      <i className={action.icon} />
                    </IconButton>
                  </div>
                </Tooltip>
                {secondaryActions.length !== i + 1 && (
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    className='mx-[14px] my-auto h-[24px]'
                  />
                )}
              </Fragment>
            ))}
            {primaryActions?.map((action, i) => (
              <Fragment key={action.title || action.tooltip}>
                {i === 0 && (
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    className='mx-[14px] my-auto h-[24px]'
                  />
                )}
                <Button
                  onClick={() => action.execute(entity)}
                  variant={action.title ? 'outlined' : 'text'}
                  className='my-[16px] mr-[12px] last:mr-[0px]'
                  disabled={action.disabled?.()}
                >
                  {action.icon && (
                    <i
                      className={`${action.icon} text-[18px] mr-[5px] text-primary align-middle`}
                    />
                  )}

                  {action.title ?? ''}
                </Button>
              </Fragment>
            ))}
          </Box>
        </ActionsToolBar>
      </Container>
    </ActionsBar>
  );
}
