import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';

import type { UpsertServiceAccountState } from './upsertServiceAccountFlow';

import { RolePermissions } from '../permissions/rolePermissions';

export const ServiceAccountsScopesSelection = (
  props: StepProps<UpsertServiceAccountState>
) => {
  const dal = useDAL();
  const { permissions, isAuthorized } = useRoles();
  const [permissionsList, setPermissionsList] = useState<string[]>(
    Object.keys(permissions).filter((x) =>
      permissions[x].scopes.every((x) => isAuthorized(x, props.state.scopes))
    )
  );

  useEffect(() => {
    const scopes = permissionsList.reduce<string[]>((agg, permission) => {
      permissions[permission].scopes.forEach((scope) => {
        if (!agg.includes(scope)) {
          agg.push(scope);
        }
      });
      return agg;
    }, []);
    props.setState((state) => ({ ...state, scopes }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsList]);

  return (
    <>
      <StepContainer
        navigationComponent={
          props.error && (
            <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
              <i className='ri-error-warning-line mr-[8px] text-[18px]' />
              {props.error}
            </Stack>
          )
        }
        hideStepper
        onBackClick={props.back}
        canGoNext={() => props.state.scopes.length > 0}
        nextButtonText={
          props.state.clientId ? 'Save Changes' : 'Create Service Account'
        }
        onNextClick={() => {
          if (props.state.clientId) {
            void dal.serviceAccounts
              .update(props.state.clientId, props.state)
              .then(props.finish);
          } else {
            void dal.serviceAccounts.create(props.state).then((x) => {
              props.setState((state) => ({
                ...state,
                clientId: x.serviceAccount.clientId,
                clientSecret: x.clientSecret,
              }));
              props.setNextStep(
                props.currentStep.next?.find((s) => s.name === 'Token Reveal')
              );
            });
          }
        }}
      >
        <Stack
          className='px-[60px]'
          gap='24px'
          color={'var(--mui-palette-text-primary)'}
        >
          <Typography>{`Choose permissions to give users using this service account:`}</Typography>
          <RolePermissions
            scopes={props.state.scopes}
            isProjectRole={true}
            isBuiltInRole={false}
            editMode
            onChange={(permission, value) =>
              setPermissionsList((state) =>
                value
                  ? [...state, permission]
                  : state.filter((x) => x !== permission)
              )
            }
          />
        </Stack>
      </StepContainer>
    </>
  );
};
