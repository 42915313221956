import {
  Card,
  CardContent,
  Divider,
  Skeleton,
  Stack,
  styled,
  Switch,
  Typography,
  type Theme,
} from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import OptionMenu from '@core/components/option-menu';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/accordion';
import ConditionViewer from '@/components/conditions/ConditionViewer';
import { backupPolicies } from '@/components/queryBuilder/properties';
import { Link } from '@/components/shared/link';
import VaultTag from '@/components/vaults/VaultTag';
import { useRoles } from '@/contexts/useRoles';
import type { BackupVault } from '@/data/vaults/backupVault';
import { StringOperator } from '@/types/advanceFilter';

import { policySupportedProperties } from './BackupPolicyConditionEditor';
import BackupPolicyRetentionSchedulesTable from './BackupPolicyRetentionSchedulesTable';

import { createInventoryLink } from '../../inventory/[[...ids]]/inventoryCondition';

const BPCard = styled(Card)((props: {
  disabled: boolean;
  theme?: Theme;
  style?: object;
}) => {
  return {
    border: '1px solid var(--mui-palette-divider)',
    backgroundColor:
      props.theme?.palette.mode === 'dark'
        ? props.disabled
          ? '#192a3570'
          : 'var(--mui-palette-background-paper)'
        : props.disabled
          ? 'rgba(255,255,255,0.5)'
          : '#fff',
    ...props.theme?.typography.body2,
    textAlign: 'left',
    color: props.theme?.palette.text.secondary,
    minWidth: '550px',
    minHeight: '262px',
    boxShadow: 'none',
    paddingTop: '12px',
    borderRadius: '16px',
    '& .MuiCardContent-root': {
      padding: '24px',
      paddingTop: 0,
    },
    ...props.style,
  };
});

const BoldText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
}));

export interface BackupPolicyCardProps {
  loading?: boolean;
  policy?: BackupPolicy;
  vaults?: BackupVault[];
  defaultExpanded?: boolean;
  onEdit?: (policy: BackupPolicy) => void;
  onDelete?: (policy: BackupPolicy) => void;
  onReview?: (policy: BackupPolicy) => void;
  onToggleEnabled?: (policy: BackupPolicy) => void;
  style?: object;
}

export default function BackupPolicyCard(props: BackupPolicyCardProps) {
  const { isAuthorized } = useRoles();
  const [vaultsSectionExpanded, setVaultsSectionExpanded] = useState(
    !!props.defaultExpanded
  );
  const [enabled, setEnabled] = useState(true);
  const matchingVaults = props.vaults
    ?.filter((vault) =>
      props.policy?.schedules.find((schedule) => schedule.vaultId === vault.id)
    )
    .slice(0, 2);

  const toggleEnabled = () => {
    if (props.policy) {
      setEnabled(!enabled);
      props.onToggleEnabled?.(props.policy);
    }
  };

  useEffect(() => {
    if (props.policy) {
      setEnabled(props.policy.enabled);
    }
  }, [props.policy]);

  return (
    <BPCard
      disabled={!enabled}
      style={props.style}
      data-testid='backup-policy-card'
    >
      {props.loading && (
        <>
          <CardContent>
            <div style={{ marginTop: '20px' }}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ marginBottom: '40px' }}
              >
                <Skeleton height={30} width={150} />
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Skeleton
                    height={30}
                    width={40}
                    sx={{ marginRight: '10px' }}
                  />
                  <Divider
                    sx={{ height: '16px', opacity: 0.5 }}
                    orientation='vertical'
                  />
                  <Skeleton
                    height={30}
                    width={40}
                    sx={{ marginLeft: '10px' }}
                  />
                </Stack>
              </Stack>
              <Skeleton height={20} sx={{ margin: '16px 0' }} />
              <Divider sx={{ opacity: 0.5 }} />
              <Skeleton height={20} sx={{ margin: '16px 0' }} />
              <Divider sx={{ opacity: 0.5 }} />
              <Skeleton height={20} sx={{ margin: '16px 0' }} />
            </div>
          </CardContent>
        </>
      )}
      {!props.loading && props.policy && (
        <>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              margin: '12px 24px',
            }}
          >
            <div>
              <Typography variant='h3'>{props.policy.name}</Typography>
            </div>
            <Stack direction='row' alignItems='center' gap='16px'>
              <Link
                prefetch={false}
                // Navigation here to the inventory instead of the backup policy card, as at the moment,
                // the inner inventory inside backupPolicyPage isn't functioning properly
                // Should be: href={`/backup-policy/${props.policy.id}`}
                href={createInventoryLink([
                  {
                    type: 'String',
                    operator: StringOperator.Equals,
                    value: [props.policy.id],
                    property: backupPolicies.name,
                  },
                ])}
                disabled={!isAuthorized('read:inventory')} // fix this to check read:backup-policies once directing to backupPolicyPage
                style={{ color: 'var(--primary-color)' }}
              >
                <BoldText variant='h5'>
                  {props.policy.attachedResources ?? 0} Resources
                  <i className='material-symbols-arrow-right-alt ml-[8px] align-middle' />
                </BoldText>
              </Link>

              {props.onEdit && props.onDelete && (
                <>
                  <Divider sx={{ height: '16px' }} orientation='vertical' />
                  <OptionMenu
                    iconClassName='text-textPrimary'
                    icon='material-symbols-more-horiz'
                    options={[
                      {
                        text: 'Edit',
                        menuItemProps: {
                          disabled: !isAuthorized('update:policies'),
                          onClick: () => {
                            if (!props.policy) return;
                            props.onEdit?.(props.policy);
                          },
                        },
                      },
                      {
                        text: 'Delete',
                        menuItemProps: {
                          disabled: !isAuthorized('delete:policies'),
                          onClick: () => {
                            if (!props.policy) return;
                            props.onDelete?.(props.policy);
                          },
                        },
                      },
                    ]}
                  />
                </>
              )}
              {props.onToggleEnabled && (
                <Switch
                  disabled={!isAuthorized('update:policies')}
                  checked={enabled}
                  onChange={toggleEnabled}
                />
              )}
            </Stack>
          </Stack>

          <CardContent sx={{ marginTop: '24px' }}>
            <Accordion defaultExpanded={props.defaultExpanded}>
              <AccordionSummary>
                <BoldText>Conditions</BoldText>
              </AccordionSummary>
              <AccordionDetails>
                {props.policy.condition && (
                  <ConditionViewer
                    condition={props.policy.condition}
                    supportProperties={policySupportedProperties.flat()}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            <Divider sx={{ margin: '12px -24px' }} />
            <Accordion
              expanded={vaultsSectionExpanded}
              onClick={() => {
                setVaultsSectionExpanded(!vaultsSectionExpanded);
              }}
            >
              <AccordionSummary>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  className='w-full'
                >
                  <BoldText>Frequency and retention</BoldText>
                  {!vaultsSectionExpanded && (
                    <div>
                      {matchingVaults?.length &&
                        matchingVaults.map((vault) => (
                          <VaultTag key={vault.id} vault={vault} />
                        ))}
                    </div>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <BackupPolicyRetentionSchedulesTable
                  policy={props.policy}
                  vaults={props.vaults}
                />
              </AccordionDetails>
            </Accordion>
            <Divider sx={{ margin: '12px -24px' }} />
            <Accordion>
              <AccordionSummary>
                <BoldText>Resources</BoldText>
              </AccordionSummary>
              <AccordionDetails>
                <Stack sx={{ fontWeight: 500 }}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ height: '40px' }}
                  >
                    <div>Assigned to policy</div>
                    <Stack direction='row' alignItems='center'>
                      {props.policy.attachedResources ?? 0}
                      <i className='material-symbols-arrow-right-alt ml-[8px]' />
                    </Stack>
                  </Stack>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ height: '40px' }}
                  >
                    <Stack direction='row' alignItems='center'>
                      <i className='material-symbols-subdirectory-arrow-right-rounded mr-[16px]' />
                      <div>Automatically assigned</div>
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                      {props.policy.attachedResources ?? 0}
                      <i className='material-symbols-arrow-right-alt ml-[8px]' />
                    </Stack>
                  </Stack>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ height: '40px' }}
                  >
                    <Stack direction='row' alignItems='center'>
                      <i className='material-symbols-subdirectory-arrow-right-rounded mr-[16px]' />
                      <div>Manually assigned</div>
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                      {props.policy.include?.length ?? 0}
                      <i className='material-symbols-arrow-right-alt ml-[8px]' />
                    </Stack>
                  </Stack>
                  <Divider sx={{ margin: '12px 0' }} />
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ height: '40px' }}
                  >
                    <div>Excluded from policy</div>
                    <Stack direction='row' alignItems='center'>
                      {props.policy.exclude?.length ?? 0}
                      <i className='material-symbols-arrow-right-alt ml-[8px]' />
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </>
      )}
    </BPCard>
  );
}
