import {
  BackupStatus,
  InventoryResourceResourceTypeEnum,
  type InventoryResource,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import InfoSnackbar from '@/components/infoSnackbar';
import { InstancePanel } from '@/components/panels/instancePanel';
import { useFields } from '@/components/table/hooks';
import { useRoles } from '@/contexts/useRoles';
import { useRouting } from '@/contexts/useRouting';
import { useDAL } from '@/data/dal';
import {
  InventoryResourceActionsKeys,
  type Action,
} from '@/data/inventory/actions';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import { inventoryItemFields } from './inventoryItemFields';

export const InventoryInstancePanel = ({
  id,
  onClose,
  onRowContentChange,
}: {
  id: string;
  onClose: () => void;
  onRowContentChange?: () => Promise<void>;
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isBackupInProgress, setIsBackupInProgress] = useState(false);
  const dal = useDAL();
  const { isAuthorized } = useRoles();
  const routing = useRouting();

  const { isLoading, body, mutate } = dal.inventory.get(id);

  const onEntityChange = async () => {
    await onRowContentChange?.();
    await mutate();
  };

  const fields = useFields(inventoryItemFields);
  const vaults = useBackupVaults();

  const triggerTakeSnapshot = (resource: InventoryResource) => {
    if (isBackupInProgress) {
      console.error('backup already in progress');
      return;
    }
    setIsBackupInProgress(true);

    if (vaults.loading) {
      console.error('vaults are still loading');
      return;
    }

    if (!vaults.data?.length) {
      console.error('no vaults found');
      return;
    }

    const vaultId = vaults.data[0].id;

    setSnackbarOpen(true);

    void dal.jobs.backup
      .create(resource.id, vaultId, 7)
      .finally(() => setIsBackupInProgress(false));
  };

  const takeSnapshotAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.TakeSnapshot,
    tooltip: 'Take Snapshot',
    icon: 'material-symbols-backup-outline-rounded',
    disabled: () =>
      !isAuthorized('create:jobs') ||
      isBackupInProgress ||
      body?.backupStatus === BackupStatus.Terminated ||
      body?.backupStatus === BackupStatus.Disconnected,
    execute: (resource: InventoryResource) => {
      triggerTakeSnapshot(resource);
    },
    predicate: () =>
      body?.resourceType === InventoryResourceResourceTypeEnum.Ec2 ||
      body?.resourceType === InventoryResourceResourceTypeEnum.Rds ||
      body?.resourceType === InventoryResourceResourceTypeEnum.S3 ||
      body?.resourceType === InventoryResourceResourceTypeEnum.AtlasCluster,
  };

  const triggerExcludeBackup = async (resource: InventoryResource) => {
    await dal.inventory.resources
      .updateExclusion({
        resourceIds: [resource.id],
        excluded: true,
      })
      .then(() => {
        void onEntityChange();
      });
  };

  const triggerAllowBackups = async (resource: InventoryResource) => {
    await dal.inventory.resources
      .updateExclusion({
        resourceIds: [resource.id],
        excluded: false,
      })
      .then(() => {
        void onEntityChange();
      });
  };

  const allowBackupsAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.AllowBackups,
    disabled: () => !isAuthorized('update:exclude_resource'),
    title: 'Cancel Backup Exclusion',
    tooltip: 'Cancel Backup Exclusion',
    icon: 'material-symbols-add-circle-outline',
    execute: (resource: InventoryResource) => {
      void triggerAllowBackups(resource);
    },
    predicate: () => body?.backupStatus === BackupStatus.ExcludedFromBackup,
  };

  const excludeFromBackupAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.ExcludeFromBackup,
    disabled: () => !isAuthorized('update:exclude_resource'),
    title: 'Exclude from Backup',
    tooltip: 'Exclude from Backup',
    icon: 'material-symbols-hide-source-outline-rounded',
    execute: (resource: InventoryResource) => {
      void triggerExcludeBackup(resource);
    },
    predicate: () => body?.backupStatus !== BackupStatus.ExcludedFromBackup,
  };

  const triggerExploreDB = (resource: InventoryResource) => {
    routing.push(`/db/${resource.id}`);
  };

  const exploreDBAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.Explore,
    title: 'Database Explorer',
    tooltip: 'Database Explorer',
    icon: 'material-symbols-frame-inspect-rounded',
    disabled: () => !isAuthorized('read:db_explorer'),
    execute: (resource: InventoryResource) => {
      triggerExploreDB(resource);
    },
    predicate: () =>
      body?.resourceType === InventoryResourceResourceTypeEnum.Rds ||
      body?.resourceType === InventoryResourceResourceTypeEnum.AtlasCluster ||
      (body?.resourceType === InventoryResourceResourceTypeEnum.Ec2 &&
        !!body?.applicationsPaths?.length),
  };

  const triggerExploreFiles = (resource: InventoryResource) => {
    routing.push(`/explorer?resourceId=${resource.id}&path=`);
  };

  const exploreFilesAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.Explore,
    title: 'File Explorer',
    tooltip: 'File Explorer',
    icon: 'material-symbols-frame-inspect-rounded',
    disabled: () => !isAuthorized('read:file_explorer'),
    execute: (resource: InventoryResource) => {
      triggerExploreFiles(resource);
    },
    predicate: () =>
      body?.resourceType === InventoryResourceResourceTypeEnum.Ec2 ||
      body?.resourceType === InventoryResourceResourceTypeEnum.S3,
  };

  const actions: Action<InventoryResource>[] = [
    exploreFilesAction,
    exploreDBAction,
    takeSnapshotAction,
    excludeFromBackupAction,
    allowBackupsAction,
  ].filter((action) => (action.predicate ? action.predicate() : true));

  return (
    <>
      <InstancePanel
        entity={body}
        resourceId={body?.id || ''}
        onClose={onClose}
        fields={fields}
        actions={actions}
        isLoading={isLoading}
        openSections={{
          Properties: false,
          Classifications: false,
          SourceLocationFields: false,
          BackupDetailsFields: false,
        }}
        onEntityChange={onEntityChange}
      />
      <InfoSnackbar
        message={'Backup job started.'}
        isOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
};
